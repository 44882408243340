<template>
  <v-app-bar app dense clipped-left class="elevation-1" color="blue darken-4">
    <v-app-bar-nav-icon>
      <template v-slot>
        <v-icon color="white" @click="setDrawer(!showDrawer)">mdi-menu</v-icon>
      </template>
    </v-app-bar-nav-icon>

    <v-toolbar-title
      class="white--text"
      @click="$router.push({ name: 'Index' })"
      style="cursor: pointer"
      >報修平台</v-toolbar-title
    >

    <v-spacer></v-spacer>

    <v-btn v-if="!userData.access_token" text color="white" @click="loginADAL"
      >登入</v-btn
    >
    <template v-else>
      <div class="white--text">
        {{ userData.userName }}
      </div>
      <v-btn text color="white" class="ml-2" @click="logoutAD">登出</v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AuthenticationADAL from "@/plugins/AuthenticationADAL";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userData: "user/userData",
      showDrawer: "settings/showDrawer",
    }),
  },
  methods: {
    ...mapActions({
      setDrawer: "settings/setDrawer",
      logoutAD: "user/logout",
    }),
    loginADAL() {
      if (!AuthenticationADAL.isAuthenticated()) {
        AuthenticationADAL.signIn();
      }
    },
  },
};
</script>
