<template>
  <v-overlay :value="loadingOverlay" z-index="203">
    <v-progress-circular indeterminate size="80"></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      loadingOverlay: "settings/showLoadingOverlay",
    }),
  },
};
</script>
