<template>
  <v-navigation-drawer app clipped :value="showDrawer" @input="setDrawer">
    <v-list dense>
      <template v-for="(option, index) in filterNav">
        <ListItem
          v-if="!option.items || option.items.length === 0"
          :key="index"
          :item="option"
        />
        <ListGroup v-else :key="index" :item="option" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import nav from "./nav.js";
import ListGroup from "./DrawerItems/ListGroup.vue";
import ListItem from "./DrawerItems/ListItem.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ListGroup,
    ListItem,
  },
  computed: {
    ...mapGetters({
      showDrawer: "settings/showDrawer",
    }),
    filterNav() {
      const filterNav = nav.map((item) => {
        const route = this.$router.resolve({ path: item.url });
        if (!route.resolved.meta.loginRequired) return item;
      });
      return this.$store.getters["user/userData"].access_token
        ? nav
        : filterNav.filter((item) => item);
    },
  },
  methods: {
    ...mapActions({
      setDrawer: "settings/setDrawer",
    }),
  },
};
</script>
