<template></template>

<script>
import { mapActions, mapGetters } from "vuex";
import AuthenticationADAL from "@/plugins/AuthenticationADAL";

export default {
  computed: {
    ...mapGetters({
      userData: "user/userData",
    }),
  },
  async mounted() {},
  methods: {
    ...mapActions({
      setADALData: "user/setADALData",
    }),
  },
};
</script>
