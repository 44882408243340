<template>
  <v-list-item link :to="item.url" active-class="primary--text">
    <v-list-item-icon>
      <v-icon v-text="item.icon || 'mdi-circle-small'"></v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ item.title }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
};
</script>
