<template>
  <div>
    <TheLayoutAppBar />
    <TheLayoutDrawer />
    <LoadingOverlay />
    <CheckAuthStatus />
    <v-main>
      <v-container fluid class="px-10 py-8 mainContainer">
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import TheLayoutAppBar from "./TheLayoutAppBar.vue";
import TheLayoutDrawer from "./TheLayoutDrawer.vue";
import LoadingOverlay from "@/components/LoadingOverlay";
import CheckAuthStatus from "@/components/CheckAuthStatus";
import { mapActions, mapGetters } from "vuex";
import AuthenticationADAL from "@/plugins/AuthenticationADAL";
import User from "@/api/User";

export default {
  components: {
    TheLayoutAppBar,
    TheLayoutDrawer,
    LoadingOverlay,
    CheckAuthStatus,
  },
  mounted() {
    this.checkAuth();
  },
  async updated() {
    console.log("updated");
    this.checkAuth();
  },
  computed: {
    ...mapGetters({
      userData: "user/userData",
    }),
  },
  methods: {
    ...mapActions({
      setADALData: "user/setADALData",
      setRoles: "user/setRoles",
    }),
    async checkAuth() {
      console.log("checkAuth", AuthenticationADAL.isAuthenticated());
      if (AuthenticationADAL.isAuthenticated()) {
        try {
          const access_token = await AuthenticationADAL.acquireToken();
          if (!this.userData.access_token || !this.userData.name) {
            const userName = await AuthenticationADAL.getUserName();
            await this.setADALData({ access_token, userName });
          }
        } catch (err) {
          console.error(err);
          Swal.fire({
            title: "您的驗證已過期，請重新登入",
            icon: "warning",
            confirmButtonColor: "#3085d6",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch("user/logout");
            }
          });
        }
      }

      let roleList = [];
      if (this.userData.access_token && this.userData.name) {
        try {
          const { data } = await User.getRoles(this.userData.name);
          console.log(data);
          roleList = data;
        } catch (err) {
          console.error(err);
        }
      }
      this.setRoles(roleList);
    },
  },
};
</script>

<style lang="scss" scoped>
.mainContainer {
  min-height: calc(100vh - 48px);
  background: #f5f6fa;
}
</style>
