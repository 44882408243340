const nav = [
  // {
  //   title: "大項目",
  //   icon: "mdi-file-document-outline",
  //   items: [
  //     {
  //       title: "小項目1",
  //       url: "/search",
  //       icon: "mdi-file-document-outline",
  //       permission: "queryOrder",
  //       items: [
  //         {
  //           title: "迷你項目",
  //           url: "/refund",
  //           icon: "mdi-file-document-outline",
  //         },
  //       ],
  //     },
  //     {
  //       title: "小項目2",
  //       url: "/refund",
  //       icon: "mdi-file-document-outline",
  //       permission: "cancelTicket",
  //     },
  //   ],
  // },
  {
    title: "新增報修",
    icon: "mdi-text-box-plus-outline",
    url: "/create",
  },
  {
    title: "工單管理",
    icon: "mdi-file-outline",
    url: "/ticket",
  },
  // {
  //   title: "帳號管理",
  //   icon: "mdi-account-cog",
  // },
];

export default nav;
