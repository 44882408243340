<template>
  <v-list-group no-action :sub-group="subGroup" color="primary">
    <template v-slot:activator>
      <v-list-item-icon>
        <v-icon v-text="item.icon || 'mdi-circle-small'" color="test"></v-icon>
      </v-list-item-icon>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </template>

    <template v-for="(cItem, cKey) in item.items">
      <ListItem
        v-if="!cItem.items || cItem.items.length === 0"
        :key="cKey"
        :item="cItem"
      />
      <ListGroup v-else :key="cKey" :item="cItem" :sub-group="true" />
    </template>
  </v-list-group>
</template>

<script>
import ListItem from "./ListItem.vue";
// import ListGroup from "@/containers/DrawerItems/ListGroup.vue";

export default {
  components: {
    ListGroup: () => import("@/containers/DrawerItems/ListGroup.vue"),
    ListItem,
  },
  props: {
    item: {
      type: Object,
      require: true,
    },
    noAction: {
      type: Boolean,
      default: true,
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
