/**
 * 系統使用者
 */
import settings from "@/settings";
import axios from "axios";
class User {
  getRoles(userName) {
    return axios.get(
      `${settings.api.fullPath}/job_ticket/v1/ask_role/${userName}`
    );
  }
}

var user = new User();
export default user;
